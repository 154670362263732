import axios, { AxiosError, Method } from 'axios';
import BaseSetting from '@config/BaseSetting';

interface QueryArg {
  url: string;
  method?: Method;
  body?: any;
  params?: any;
  headers?: any;
}

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    config.withCredentials = true;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const axiosBaseQuery = ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, body, params, headers }: QueryArg) => {
    try {
      const result = await axiosInstance({
        url: url,
        method,
        data: body,
        params,
        headers
      });
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  };

export default axiosBaseQuery;
